<template>
    <div class="main dashboard">
        <b-modal v-model="showModal"
             @ok="close"
             @cancel="close"
             size="lg"
             header-class="card-header pause"
             class="pause-txt">
        <div class="row d-flex modal-text">
            <h6>{{ activeLanguage.dashboard.text_success }}</h6>
        </div>
        </b-modal>
        <div class="container">
            <div class="title">
                <h1>Dashboard</h1>
                <p class="intro">
                   {{ activeLanguage.dashboard.intro }} 
                </p>
            </div>
            <div class="col-md-11 block-statistics">
                <div class="row">
                    <div class="col-md-12 d-flex available-block">
                        <div class="col-md-2 block-1">
                            <h1>{{ activeLanguage.dashboard.available }}</h1>
                        </div>
                        <div class="col-md-4 padd-top-13">
                            <p class="text-title">{{ activeLanguage.dashboard.total_transactions_not_paid }}</p>
                            <h3 class="number-style">{{ data.available[0].qty }}</h3>
                        </div>
                        <div class="col-md-4 padd-top-13">
                            <p class="text-title">{{ activeLanguage.dashboard.payout_available }}</p>
                            <h3 class="number-style">€{{ numberFormat(data.available[0].payout, 2) }}</h3>
                        </div>
                        <div class="col-md-2 block-button">
                            <button class="btn btn-payout" :disabled="data.available[0].payout > 0 ? false : true" @click="payout" :class="data.available[0].payout > 0 ? 'btn-payout-simple' : 'btn-payout-grey'">{{ activeLanguage.dashboard.btn }}</button>
                        </div>
                    </div>
                    <div class="col-md-12 d-flex total-block">
                        <div class="col-md-1 block-1">
                            <h1>{{ activeLanguage.dashboard.total }}</h1>
                        </div>
                        <div class="col-md-3 padd-top-13">
                            <p class="text-title">{{ activeLanguage.dashboard.total_transactions_month }}</p>
                            <h3 class="number-style">{{ data.thisMonth }}</h3>
                        </div>
                        <div class="col-md-3 padd-top-13">
                            <p class="text-title">{{ activeLanguage.dashboard.total_transactions }}</p>
                            <h3 class="number-style">{{ data.totalTransactions }}</h3>
                        </div>
                        <div class="col-md-3 padd-top-13">
                            <p class="text-title">{{ activeLanguage.dashboard.total_past_payouts }}</p>
                            <h3 class="number-style">€{{ data.totalPayout }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            data: {},
            showModal: false
        }
    },
    mounted() {
        this.getStatistics()
    },
    methods: {
        getStatistics() {
            billinkAxios.get('/app/reseller/statistics')
                .then(({ data }) => {
                    this.data = data
                }).catch(er => console.log(err))
        },
        numberFormat(number) {
            return Helper.number_format(number, 2)
        },
        payout() {
            billinkAxios.post('/app/reseller/payout')
                .then(({ data }) => {
                    this.open()
                    window.location.reload()
                }).catch(err => console.log(err))
        },
        close () {
            this.showModal = false
        },
        open () {
            this.showModal = true
        }
    },
    computed: {
        ...mapGetters({
            activeLanguage: 'getResellerLanguage'
        })
    }
}
</script>
<style lang="scss" scoped>
    .block-statistics {
        border-top: 1px solid gray;
        padding-top: 20px;
    }
    .d-flex {
        display: flex;
    }
    .text-title {
        text-transform: uppercase;
        font-size: 11px;
    }
    .block-1 {
        display: flex;
        align-items: center;
    }
    .available-block{
        background: #F7F7F7;
        height: 84px;
        p {
            margin-bottom: 0;
        }
        h1 {
            font-weight: 100;
            font-size: 23px;
        }
    }
    .total-block {
        background: #F7F7F7;
        height: 84px;
        margin-top:10px;
        p {
            margin-bottom: 0;
        }
        h1 {
            font-weight: 100;
            font-size: 23px;
        }
    }
    .number-style {
        color: #7DAFF0;
        font-weight: 200;
    }
    .btn-payout {
        height: 100%;
    }
    .btn-payout-grey {
        background: grey;
    }
    .btn-payout-simple {
         background: #4db5af;
    }
    .padd-top-13 {
        padding-top: 13px;
    }
    .block-button {
        padding: 0;
        margin-left: 12px;
        button {
            width: 100%;
            font-weight: bold;
            font-size: 17px;
        }
    }
    .modal-text {
        padding-left: 36px;
        padding-right: 36px;
        h6 {
            text-transform: none;
        }
    }
</style>